.loader {
  display: inline-block;
  width: 40px;
  height: 20px;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.loader div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.loader div:nth-child(1) {
  left: 0;
  background: #000;
  animation: loaderAnimationReverse 2s linear infinite;
}

.loader div:nth-child(2) {
  left: 0;
  background: #FFBC11; /* Yellow ball */
  animation: loaderAnimation 2s linear infinite;
}

@keyframes loaderAnimation {
  0%, 100% {
      transform: translateX(20px) rotate(360deg);
  }
  50% {
      transform: translateX(0) rotate(0deg);
  }
}

@keyframes loaderAnimationReverse {
  0%, 100% {
      transform: translateX(0) rotate(0deg);
  }
  50% {
      transform: translateX(20px) rotate(360deg);
  }
}