.info-window-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    padding: 10px;
    max-width: 200px; /* Adjust width as needed */
    position: relative; /* Allows for better positioning of child elements */
    z-index: 1000; /* Ensures it appears above other elements */
  }
  
  .info-window-card h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .info-window-card a {
    display: block;
    margin-top: 8px;
    padding: 5px 10px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .info-window-card a:hover {
    background-color: #0056b3; /* Darker blue for hover effect */
  }
.gm-style-iw-chr{
    display: none;
}
.gm-style-iw-d{
    overflow:visible !important;
}  