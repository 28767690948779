body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.google-map-container {
  height: 400vh !important; /* Set an appropriate height */
  width: 100% !important; /* Set an appropriate width */
}

.loadingTable {
  width: 80px;
  height: 80px;
  border-color: #d4cd41;
  border-right: 2px solid transparent;
  margin: 70px auto;
}

$background-color_1: #ccc;

.holo-timeline {
  &::before {
    background-color: $background-color_1;
    content: '';
    height: 100%;
    left: 20px;
    position: absolute;
    transform: translateX(-50%);
    width: 2px;
  }
  ul {
    li {
      cursor: pointer;
      margin: 0;
      padding: 5px 30px 40px;
      width: 50%;
      &:nth-child(odd) {
        float: left;
        margin-bottom: 20px;
        padding: 0 0 0 60px;
        text-align: left;
        width: 100%;
        .timeline-dots {
          left: 12px;
          left: 12px;
          right: auto;
          right: auto;
        }
      }
      &:nth-child(2n) {
        float: left;
        margin-bottom: 20px;
        padding: 0 0 0 60px;
        text-align: left;
        width: 100%;
        .timeline-dots {
          left: 12px;
          left: 12px;
          right: auto;
          right: auto;
        }
      }
    }
  }
  li {
    .timeline-dots {
      background: #fff !important;
      height: 15px;
      width: 15px;
      height: 15px;
      width: 15px;
    }
    .timeline-dots1 {
      background: #fff;
      border: 3px solid #4788ff;
      border-radius: 90px;
      height: 40px;
      left: 0;
      line-height: 25px;
      padding: 5px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 40px;
    }
  }
}

.pending-row {
  background-color: #f2bf3f;
  color: #000;
  button {
    color: #000 !important;
  }
}
